/*.ant-form-item-control-input, .ant-select-selection {*/
/*    background-color: red;*/
/*}*/

.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-select-dropdown {
    background-color: #373233;
}

.ant-select-arrow {
    color: #0762f9;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px #373233 solid;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #db9114;
}

.center{
    display: flex;
    justify-content: center;
}

.ant-select{
    color: rgb(181 181 181);
}

body{
    background-color: #376a3b;
}

.ant-btn, .ant-btn-primary, .ant-btn-lg, .ant-btn-primary:focus, .ant-btn-primary:hover{
    background-color: #db9114;
    background: #db9114;
}


.ant-form-item-label > label, ant-col, ant-form-item{
    color:white;
}

.robo-text{
    font: 14px 'roboto-italic' , Serif;
}

::placeholder{
    color: white;
}